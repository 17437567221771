import { Component, inject, input, output, signal } from '@angular/core';
import { catchError, finalize, of, tap } from 'rxjs';
import {
  EventAttendee,
  EventAttendeesService,
  EventAttendeeStatusEnum,
} from 'gen';
import { ModalWithServiceComponent } from '../modal-with-service/modal-with-service.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '@shared/services';

@Component({
  selector: 'mlk-attendees-action',
  standalone: true,
  templateUrl: './attendees-action.component.html',
  styleUrl: './attendees-action.component.scss',
})
export class AttendeesActionComponent {
  /** Injection of {@link EventAttendeesService}. */
  private readonly eventAttendeesService = inject(EventAttendeesService);

  /** Injection of {@link EventAttendeesService}. */
  private readonly toastService = inject(ToastService);

  /** Injection of {@link NgbModal}. */
  private readonly ngbModal = inject(NgbModal);

  readonly attendeeId = input.required<string>();

  readonly status = input<EventAttendeeStatusEnum | undefined>(undefined);

  readonly actionSucceed = output<EventAttendee>();

  readonly actionInprogress = signal(false);

  onAcceptAttend(): void {
    this.actionInprogress.set(true);
    this.eventAttendeesService
      .eventAttendeesConfirmCreate({ id: this.attendeeId() })
      .pipe(
        tap(evAttendee => {
          this.actionSucceed.emit(evAttendee);
          this.openAcceptModal();
        }),
        catchError(() => {
          this.toastService.showDanger(
            "Suite à un problème technique, votre événement n'a pu être supprimé"
          );
          return of(null);
        }),
        finalize(() => this.actionInprogress.set(false))
      )
      .subscribe();
  }

  onRefuseAttend(): void {
    this.actionInprogress.set(true);
    this.eventAttendeesService
      .eventAttendeesRefuseCreate({ id: this.attendeeId() })
      .pipe(
        tap(evAttendee => {
          this.actionSucceed.emit(evAttendee);
          this.openRefuseModal();
        }),
        catchError(() => {
          this.toastService.showDanger(
            "Suite à un problème technique, votre événement n'a pu être supprimé"
          );
          return of(null);
        }),
        finalize(() => this.actionInprogress.set(false))
      )
      .subscribe();
  }

  openAcceptModal(): void {
    const ngbModalRef = this.ngbModal.open(ModalWithServiceComponent, {
      centered: true,
    });
    ngbModalRef.componentInstance.title = 'La demande a bien été acceptée';
    ngbModalRef.componentInstance.icon = 'accepted';
    ngbModalRef.componentInstance.buttonOk = {
      id: 'accepter',
      label: 'Retour',
      cssClasses: 'mlk-btn-black-filled',
    };
  }

  openRefuseModal(): void {
    const ngbModalRef = this.ngbModal.open(ModalWithServiceComponent, {
      centered: true,
    });

    ngbModalRef.componentInstance.title = `La demande a bien été refusée`;
    ngbModalRef.componentInstance.content = `N'oubliez pas de contacter le participant par SMS pour lui indiquer le motif de votre refus.`;
    ngbModalRef.componentInstance.icon = 'refused';
    ngbModalRef.componentInstance.buttonOk = {
      id: 'refuser',
      label: 'Retour',
      cssClasses: 'mlk-btn-black-filled',
    };
  }
}
