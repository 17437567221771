import { Component, inject, TemplateRef } from '@angular/core';
import { ToastService } from '../../services/toast/toast.service';
import { NgTemplateOutlet } from '@angular/common';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mlk-toasts-container',
  templateUrl: './toasts-container.component.html',
  styleUrls: ['./toasts-container.component.scss'],
  host: {
    class: 'toast-container position-fixed top-0 end-0 p-3',
    style: 'z-index: 1200',
  },
  imports: [NgbToastModule, NgTemplateOutlet],
})
export class ToastsContainerComponent {
  public readonly toastService = inject(ToastService);

  isTemplate(toast: any): boolean {
    return toast.textOrTpl instanceof TemplateRef;
  }
}
