import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import {
  NgbActiveModal,
  NgbModalModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { Button } from '../../interfaces/button';
import { Router } from '@angular/router';

@Component({
  templateUrl: './modal-with-service.component.html',
  styleUrls: ['./modal-with-service.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgbTooltipModule, NgbModalModule],
})
export class ModalWithServiceComponent {
  private ngbActiveModal = inject(NgbActiveModal);

  private router = inject(Router);

  @Input() public title = '';
  @Input() public content = '';
  @Input() public icon: 'accepted' | 'refused' | 'in-progress' | null = null;
  @Input() public informationIconContent = '';
  @Input() public informationIconTitle = '';
  @Input() public buttonOk: Button | null = null;
  @Input() public buttonCancel: Button | null = null;

  public decline(): void {
    this.ngbActiveModal.close(false);
  }

  public accept(): void {
    this.ngbActiveModal.close(true);
    if (this.buttonOk?.link) {
      this.router.navigateByUrl(this.buttonOk.link);
      return;
    }

    if (this.buttonOk?.action) {
      this.buttonOk?.action();
      return;
    }
  }

  public dismiss(): void {
    this.ngbActiveModal.dismiss();
  }
}
