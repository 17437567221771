import { Component, EventEmitter, inject, Output, signal } from '@angular/core';

import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  Child,
  EventAttendeeGuest,
  GenderEnum,
  Partner,
} from '../../../../../gen';
import {
  ChangeData,
  CountryISO,
  NgxIntlTelInputModule,
  SearchCountryField,
} from 'ngx-intl-tel-input';
import { catchFormError, FormErrors } from '@common/rxjs';

@Component({
  selector: 'app-attendee-group-item',
  imports: [ReactiveFormsModule, NgxIntlTelInputModule],
  templateUrl: './attendee-group-item.component.html',
  styleUrl: './attendee-group-item.component.scss',
})
export class AttendeeGroupItemComponent {
  @Output() newItemEvent = new EventEmitter<boolean>();
  @Output() newPartner = new EventEmitter<Partner>();
  @Output() newChild = new EventEmitter<Child>();
  @Output() newGuest = new EventEmitter<EventAttendeeGuest>();

  readonly searchCountryField = [
    SearchCountryField.Iso2,
    SearchCountryField.Name,
  ];

  private readonly formBuilder = inject(FormBuilder);
  readonly formErrorFromServer = signal<FormErrors | null>(null);
  private catchErrorFormServer = catchFormError(this.formErrorFromServer);

  readonly countryISOFr = CountryISO.France;

  public addAttendeeForm = this.formBuilder.group({
    family_link: new FormControl('', {
      nonNullable: true,
      validators: Validators.required,
    }),
    first_name: new FormControl('', {
      nonNullable: true,
      validators: Validators.compose([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50),
      ]),
    }),
    last_name: new FormControl('', {
      nonNullable: true,
      validators: Validators.compose([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50),
      ]),
    }),
    gender: new FormControl<GenderEnum | ''>('', {
      nonNullable: true,
      validators: Validators.required,
    }),
    phone_number: new FormControl<ChangeData | undefined>(undefined, {
      nonNullable: true,
      validators: Validators.required,
    }),
    email: new FormControl('', {
      nonNullable: true,
      validators: Validators.compose([
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(254),
        Validators.email,
      ]),
      updateOn: 'blur',
    }),
  });
  readonly familyLink = this.addAttendeeForm.controls.family_link;
  readonly phoneNumber = this.addAttendeeForm.controls.phone_number;
  readonly firstName = this.addAttendeeForm.controls.first_name;
  readonly lastName = this.addAttendeeForm.controls.last_name;
  readonly gender = this.addAttendeeForm.controls.gender;
  readonly email = this.addAttendeeForm.controls.email;

  public onSubmit() {
    this.newItemEvent.emit(this.addAttendeeForm.valid);
    //    if (!this.addAttendeeForm.invalid) {
    if (this.familyLink.value === 'partner') {
      const newPartnerData: Partner = {
        first_name: this.firstName.value,
        last_name: this.lastName.value,
        gender: this.gender.value as GenderEnum,
        email: this.email.value,
        phone_number: this.phoneNumber.value as string,
      };
      this.newPartner.emit(newPartnerData);
    } else if (this.familyLink.value === 'child') {
      const newChildData: Child = {
        id: '1',
        date_of_birth: '01/01/1901',
        first_name: this.firstName.value,
        last_name: this.lastName.value,
        gender: this.gender.value as GenderEnum,
      };
      this.newChild.emit(newChildData);
    } else {
      const newGuestData: EventAttendeeGuest = {
        id: '1',
        updated_at: '',
        created_at: '',
        age_range: '0-17',
        first_name: this.firstName.value,
        last_name: this.lastName.value,
        gender: this.gender.value as GenderEnum,
        email: this.email.value,
        phone_number: this.phoneNumber.value as string,
      };
      this.newGuest.emit(newGuestData);
    }
    //}
  }
}
