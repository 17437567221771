import { filter, map, Observable } from 'rxjs';

type Nullish = undefined | null;

/**
 * Helper function to filter out nullish values.
 *
 * @returns An observable that only re-emits values that aren't nullish.
 */
function notNullish<T>() {
  return function notUndefinedInner(
    source: Observable<T | Nullish>
  ): Observable<T> {
    return source.pipe(filter((e: T | Nullish): e is T => !!e));
  };
}

/**
 * Helper function to remove nullish in array values.
 *
 * @returns An observable that only re-emits array values without nullish
 */
function removeNullish<U>() {
  return function removeUndefinedInner(
    source: Observable<(U | Nullish)[]>
  ): Observable<U[]> {
    return source.pipe(map(arr => arr.filter((e: U | Nullish): e is U => !!e)));
  };
}

export { notNullish, removeNullish };
