import { DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '@shared/services';
import { MlkButtonComponent } from '@shared/ui';
import { tap } from 'rxjs';

@Component({
  imports: [ReactiveFormsModule, MlkButtonComponent],
  templateUrl: './login-dialog.component.html',
  styleUrl: './login-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginDialogComponent {
  readonly router = inject(Router);
  readonly dialogRef = inject(DialogRef);
  private readonly authenticationService = inject(AuthenticationService);
  private readonly formBuilder = inject(FormBuilder);

  readonly showPassword = signal(false);

  readonly loginForm = this.formBuilder.group({
    email: new FormControl('', {
      nonNullable: true,
      validators: Validators.compose([Validators.email, Validators.required]),
      updateOn: 'blur',
    }),
    password: new FormControl('', {
      nonNullable: true,
      validators: Validators.required,
    }),
  });

  readonly formErrorFromServer = signal('');

  readonly email = this.loginForm.controls.email;

  readonly password = this.loginForm.controls.password;

  public changePasswordVisibility(): void {
    this.showPassword.update(currentVisibility => !currentVisibility);
  }

  goTo(path: string) {
    this.router.navigateByUrl(path);
    this.dialogRef.close();
  }

  onSubmitForm() {
    this.authenticationService
      .loginWithPassword(
        this.loginForm.controls.email.value,
        this.loginForm.controls.password.value
      )
      .pipe(tap(() => this.dialogRef.close(true)))
      .subscribe({
        error: errorResponse => {
          if (errorResponse.status === 401) {
            this.formErrorFromServer.set(
              `Email et/ou mot de passe incorrect. Vous pouvez réinitialiser votre mot de passe en cliquant sur “Mot de passe oublié”. Si vous n'avez pas encore de compte, inscrivez-vous en cliquant sur “Inscrivez-vous gratuitement” au bas du formulaire.`
            );
          }
        },
      });
  }
}
