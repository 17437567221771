import {
  ChangeDetectionStrategy,
  Component,
  input,
  TemplateRef,
} from '@angular/core';
import { EventAttendee } from '../../../../../gen';
import { StatusAttendeePipe } from 'src/app/common/pipes/status-attendee.pipe';
import { NgClass } from '@angular/common';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mlk-event-attendee',
  templateUrl: './event-attendee.component.html',
  styleUrls: ['./event-attendee.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [StatusAttendeePipe, NgClass],
})
export class EventAttendeeComponent {
  public userId: string;
  public nbNewAttendee: boolean = false;

  private updateModalFromRef: NgbModalRef | null = null;

  constructor(private modalService: NgbModal) {}

  readonly eventAttendee = input.required<EventAttendee>();

  public openUpdateModalForm(content: TemplateRef<unknown>) {
    this.updateModalFromRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      backdrop: 'static',
    });
  }

  public onSubmitForm() {}

  public openAddAttendees(id: string) {}

  public cancelMember() {
    //alert('got');
  }
}
