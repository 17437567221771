import {
  ChangeDetectionStrategy,
  Component,
  Input,
  signal,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  UsersService,
  VerifyEmailTokenRequest,
  VerifyResetPasswordTokenRequest,
} from '../../../../../gen';
import { map, tap } from 'rxjs';
import { confirmEqualValidator } from '../../validators/confirm-equals.validators';
import { NgbModal, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalWithServiceComponent } from '../modal-with-service/modal-with-service.component';
import { RouterLink } from '@angular/router';
import { AsyncPipe, NgClass } from '@angular/common';
import { PASSWORD_REGEX } from '@common/constants';

type UserType = 'user' | 'ambassador';

@Component({
  selector: 'mlk-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterLink,
    NgClass,
    NgbTooltipModule,
    ReactiveFormsModule,
    AsyncPipe,
  ],
})
export class ChangePasswordComponent {
  @Input({ required: true }) public token?: string | null = null;
  @Input({ required: true }) public email?: string | null = null;
  @Input({ required: true }) public passwordType!: UserType;

  public changePasswordForm = this.formBuilder.group(
    {
      password: new FormControl('', {
        nonNullable: true,
        validators: Validators.compose([
          Validators.pattern(PASSWORD_REGEX),
          Validators.minLength(10),
          Validators.required,
        ]),
      }),
      confirmPassword: new FormControl('', {
        nonNullable: true,
        validators: Validators.compose([
          Validators.pattern(PASSWORD_REGEX),
          Validators.minLength(10),
          Validators.required,
        ]),
      }),
    },
    {
      validators: [confirmEqualValidator('password', 'confirmPassword')],
      updateOn: 'change',
    }
  );

  get passwordCtrl() {
    return this.changePasswordForm.controls.password;
  }

  get confirmPasswordCtrl() {
    return this.changePasswordForm.controls.confirmPassword;
  }

  public showPasswordError$ = this.changePasswordForm.statusChanges.pipe(
    map(
      status =>
        status === 'INVALID' &&
        this.passwordCtrl.value &&
        this.confirmPasswordCtrl.value
    )
  );

  public showPassword = signal(false);

  constructor(
    private formBuilder: FormBuilder,
    private userService: UsersService,
    private modalService: NgbModal
  ) {}

  getRequest<
    T extends VerifyEmailTokenRequest & VerifyResetPasswordTokenRequest,
  >(verifyRequest: T) {
    if (this.passwordType === 'ambassador') {
      return this.userService.usersVerifyEmailTokenCreate({
        verifyEmailTokenRequest: verifyRequest,
      });
    } else {
      return this.userService.usersVerifyResetPasswordTokenCreate({
        verifyResetPasswordTokenRequest: verifyRequest,
      });
    }
  }

  public passwordStateChange(): void {
    this.showPassword.update(isVisible => !isVisible);
  }

  public onSubmitForm(): void {
    const token = this.token;
    const email = this.email;
    if (this.changePasswordForm.valid && token && email) {
      const verifyRequest = {
        password: this.passwordCtrl.value,
        email,
        token,
      };

      const req = this.getRequest(verifyRequest);
      req
        .pipe(
          tap({
            next: () => {
              this.changePasswordForm.reset();
              this.openModalSucess();
            },
            error: () => this.openModalError(),
          })
        )
        .subscribe();
    }
  }

  private openModalSucess(): void {
    const ngbModalRef = this.modalService.open(ModalWithServiceComponent, {
      centered: true,
    });
    ngbModalRef.componentInstance.title = 'Nouveau mot de passe';
    ngbModalRef.componentInstance.buttonOk = {
      id: 'accepter',
      label: 'Se connecter',
      cssClasses: 'mlk-btn-blue-filled',
      link: '/login',
    };
    ngbModalRef.componentInstance.content =
      'Votre mot de passe a été mis a jour.';
    ngbModalRef.componentInstance.icon = 'accepted';
  }

  private openModalError(): void {
    const ngbModalRef = this.modalService.open(ModalWithServiceComponent, {
      centered: true,
    });

    ngbModalRef.componentInstance.content =
      "Votre mot de passe n'a pas été mis a jour.";
    ngbModalRef.componentInstance.icon = 'refused';
    ngbModalRef.componentInstance.buttonOk = {
      id: 'refuser',
      label: 'Réinitialiser',
      cssClasses: 'mlk-btn-black-filled',
      link: '/reset-password',
    };
  }
}
