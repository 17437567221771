import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import { EmbassiesService } from '../../../../../gen';
import { Observable, switchMap } from 'rxjs';
import { Observe } from 'src/app/common/decorators/observe';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'mlk-embassy-info',
  templateUrl: './embassy-info.component.html',
  styleUrls: ['./embassy-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe],
})
export class EmbassyInfoComponent {
  private embassyService = inject(EmbassiesService);

  @Input() public embassyId!: string;

  @Observe('embassyId') public embassyId$!: Observable<string>;

  @Input({ transform: booleanAttribute }) public canShowAddressMessage = true;

  public embassy$ = this.embassyId$.pipe(
    switchMap(aId => this.embassyService.embassiesRetrieve({ id: aId }))
  );
}
